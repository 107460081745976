body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@import "~bootstrap/dist/css/bootstrap.min.css";

.dropdown.show {
  overflow: inherit !important;
}

.table-responsive,
.table-responsive div:first-of-type {
  overflow: inherit !important;
}

.table-responsive {
  overflow-x: auto !important;
  -webkit-overflow-scrolling: touch !important;
}

.has-error {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns= 'http://www.w3.org/2000/svg' viewBox= '0 0 12 12' width= '12' height= '12' fill= 'none' stroke= '%23dc3545' %3e%3ccircle cx= '6' cy= '6' r= '4.5' /%3e%3cpath stroke-linejoin= 'round' d= 'M5.8 3.6h.4L6 6.5z' /%3e%3ccircle cx= '6' cy= '8.2' r= '.6' fill= '%23dc3545' stroke= 'none' /%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.row-disabled [role="cell"]:not(:last-of-type) {
  opacity: 0.3;
}

.toasts-container {
  z-index: 9999;
  top: 80px !important;
  right: 15px !important;
}

.setting-icon {
  font-size: 48px;
}

.side-box--overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  z-index: 99999;
  transition: background-color 300ms ease-in-out;

  &.enter-animation {
    background-color: rgba(0, 0, 0, 0.3);
  }

  &.closing {
    background-color: rgba(0, 0, 0, 0);
  }
}

.side-box--content {
  position: fixed;
  top: 0;
  right: 0;
  max-width: 600px !important;
  width: 40vw !important;
  height: 100vh;
  transform: translateX(100%);
  background-color: #fff;
  z-index: 999999;
  transition: all 300ms ease-in-out;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 50%);
  padding: 50px 15px;
  overflow-y: auto;

  &.enter-animation {
    transform: translateX(0);
  }

  &.closing {
    transform: translateX(100%);
  }

  .side-box--close {
    position: absolute;
    top: calc(15px - 50px);
    right: 0;
    font-size: 30px;

    &:hover {
      cursor: pointer;
    }
  }
}

.note--footer {
  font-size: 11px;
  margin-top: 10px;
}

.table-responsive div.progress {
  width: 270px;
  display: block;
  overflow: hidden !important;
}

.table-responsive div.progress div {
  height: 100%;
}

.hoverable:hover {
  cursor: pointer;
}

.text-break {
  //white-space: initial;
}

.flex-1 {
  flex: 1 1 100%;
}

.btn-loader {
  height: 25px;
  width: 25px;
  margin-right: 15px;
}

.img-thumbnail {
  width: 90px;
}

.form-check {
  margin-bottom: 0 !important;
  min-height: 17px !important;
}

.form-check-with-overlay {
  position: relative;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
  }
}

.rdt_Table {
  min-height: 300px;
}

.form-hidden {
  display: none;
  visibility: hidden;
}

.car-details-view {
  .form-label {
    font-weight: bold;
  }

  .form-switch {
    position: relative;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
    }
  }
}

.form-control {
  &.input-date {
    width: 120px;
  }
}

.pr-label {
  padding-right: 5px;
}

.react-datepicker-wrapper {
  width: auto;
  margin-right: 5px;
}

.cars-search-input {
  min-width: 250px;
}

.toast-container.position-absolute {
  position: fixed !important;
}

.text-no-wrap {
  white-space: nowrap;
}

.ml-small {
  margin-left: 8px;
}
